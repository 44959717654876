import { Controller } from "@hotwired/stimulus"
import { graphql } from "@/api"
import apolloClient from "@/api/client"
import { basketAddToGA4 } from "@/common/util"

const basketAddDoc = graphql(`
  mutation DsiBasketAdd($product: String!) {
    basketAdd(product: $product) {
      success

      item {
        id
        priceSek
        totalCount
      }

      status {
        numberOfSigns
        ...BasketModal_Order
      }
    }
  }
`)

export default class extends Controller {
  static values = {
    signcode: Object,
    price: Number,
  }

  static targets = ["add", "count", "notice"]

  declare signcodeValue: any
  declare hasSigncodeValue: boolean

  declare priceValue: any
  declare hasPriceValue: boolean

  declare addTarget: HTMLAnchorElement
  declare hasAddTarget: boolean

  declare countTarget: HTMLSpanElement
  declare hasCountTarget: boolean

  declare noticeTarget: HTMLSpanElement
  declare hasNoticeTarget: boolean

  async add() {
    const count = this.countTarget.textContent
    const product = this.signcodeValue
    product["count"] = count
    product["basket_id"] = Math.round(Math.random() * 99999999)
    product["campaign"] = "dsi"
    product["fixed_price"] = this.priceValue

    const resp = await apolloClient.mutate({
      mutation: basketAddDoc,
      variables: { product: JSON.stringify(product) },
    })

    if (resp.data?.basketAdd?.success) {
      const label = document.querySelector<HTMLElement>(".mainmenu .cart .cartlabel")

      if (resp.data.basketAdd.status?.numberOfSigns && label) {
        label.innerText = resp.data.basketAdd.status.numberOfSigns.toString()
      }

      const orderItem = resp.data.basketAdd.item
      if (orderItem) {
        basketAddToGA4(orderItem.id, orderItem.priceSek, orderItem.totalCount)
      }

      this.noticeTarget.classList.remove("hidden")
      document.getElementById("basket_order_button")?.classList.remove("hidden")

      setTimeout(() => {
        this.noticeTarget.classList.add("hidden")
      }, 3000)
    }
  }
}
