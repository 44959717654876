import { v4 as uuidv4 } from "uuid"
import fetch from "@/common/fetch"

const client_id = uuidv4()

declare global {
  interface Window {
    registerTxn?: number
    gtmFired?: boolean
  }
}

const url = `/order/register_txn/${window.registerTxn}`
let registered = false
const registerInit = Date.now()

const register = () => {
  if (registered) {
    return
  }

  registered = true

  // don't register 2 minutes later if tab has been asleep on mobile etc
  const expired = Date.now() - registerInit > 1000 * 60 * 2
  const body = JSON.stringify({ track: !window.gtmFired && !expired, client_id })

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body)
  } else {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body,
    })
  }
}

if (window.registerTxn) {
  window.addEventListener("pagehide", register, false)
  window.setTimeout(register, 1000 * 60)
}

export default register
