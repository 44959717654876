import { Controller } from "@hotwired/stimulus"
import EpicVite from "@skyltmax/epic_vite"
import { draggable } from "@/common/draggable"
import { toggleScroll, pushToDataLayer } from "@/common/util"

const signTagMobileLinkTemplate = (pagename: string, path: string, active: boolean) => {
  return `
  <div class="flex items-center h-11 ${active ? "bg-white-5 pointer-events-none" : "hover:bg-white-5"}">
    ${active ? "<div class='indicator bg-primary w-1 h-full mr-1 min-w-[4px] min-h-[32px]'></div>" : ""}
    <a data-gtm="sidebar-page-item" class="pl-4 text-base text-ink-100 font-medium h-full w-full flex items-center" href="${path}">
    ${pagename}
    </a>
  </div>
`
}

export default class extends Controller {
  static values = {
    signtagId: String,
    signtagName: String,
    category: String,
    index: Number,
    tag: String,
    signsCount: Number,
    signCategoryId: Number,
  }

  static targets = [
    "loadExamples",
    "displayExamples",
    "hideExamples",
    "displayProducts",
    "hideProducts",
    "signsTarget",
    "examplesScroll",
    "exampleItem",
    "hiddenProduct",
    "sortables",
    "mobileMenuButton",
    "signTagsWrapper",
    "signTagsWrapperMobile",
    "selectedCategoryName",
    "selectedCategoryLink",
    "subMenu",
    "mobileSidebar",
    "examplesMobileCategoryItem",
    "stickyEnd",
    "contentShowMore",
    "contentShowLess",
  ]

  declare signtagIdValue: string
  declare hasSigntagIdValue: boolean

  declare tagValue: string
  declare hasTagValue: boolean

  declare indexValue: number
  declare hasIndexValue: boolean

  declare signCategoryIdValue: number
  declare hasSignCategoryIdValue: boolean

  declare loadExamplesTarget: HTMLButtonElement
  declare hasLoadExamplesTarget: boolean

  declare displayExamplesTarget: HTMLButtonElement
  declare hasDisplayExamplesTarget: boolean

  declare hideExamplesTarget: HTMLButtonElement
  declare hasHideExamplesTarget: boolean

  declare displayProductsTarget: HTMLButtonElement
  declare hasDisplayProductsTarget: boolean

  declare hideProductsTarget: HTMLButtonElement
  declare hasHideProductsTarget: boolean

  declare examplesScrollTarget: HTMLDivElement
  declare hasExamplesScrollTarget: boolean

  declare exampleItemTargets: HTMLDivElement[]
  declare hasExampleItemTargets: boolean

  declare hiddenProductTargets: HTMLDivElement[]
  declare hasHiddenProductTargets: boolean

  declare sortablesTarget: HTMLDivElement
  declare hasSortablesTarget: boolean

  declare examplesMobileCategoryItemTargets: HTMLDivElement[]
  declare hasExamplesMobileCategoryItemTargets: boolean

  declare signTagsWrapperMobileTarget: HTMLDivElement
  declare hasSignTagsWrapperMobileTarget: boolean

  declare selectedCategoryNameTarget: HTMLDivElement
  declare hasSelectedCategoryNameTarget: boolean

  declare selectedCategoryLinkTarget: HTMLDivElement
  declare hasSelectedCategoryLinkTarget: boolean

  declare subMenuTarget: HTMLDivElement
  declare hasSubMenuTarget: boolean

  declare mobileSidebarTarget: HTMLDivElement
  declare hasMobileSidebarTarget: boolean

  declare stickyEndTarget: HTMLDivElement
  declare hasStickyEndTarget: boolean

  declare contentShowMoreTarget: HTMLDivElement
  declare hasContentShowMoreTarget: boolean

  declare contentShowLessTarget: HTMLDivElement
  declare hasContentShowLessTarget: boolean

  currentlyActive = 0

  get categoryIndex() {
    if (this.hasIndexValue) return this.indexValue

    return -1
  }

  toggleExamples(e?: Event) {
    e?.preventDefault()

    const examplesHidden = this.exampleItemTargets.filter(el => el.classList.contains("hidden"))
    const examplesChunk = Array.from(examplesHidden).slice(0, 16)

    if (this.hasDisplayExamplesTarget && this.hasHideExamplesTarget && this.hasExamplesScrollTarget) {
      if (examplesHidden.length === 0) {
        Array.from(this.exampleItemTargets)
          .slice(16, this.exampleItemTargets.length)
          .forEach(function (el) {
            el.classList.add("hidden")
          })

        this.displayExamplesTarget.classList.remove("hidden")
        this.hideExamplesTarget.classList.add("hidden")
        this.examplesScrollTarget?.scrollIntoView({ behavior: "smooth" })
      } else {
        examplesChunk.forEach(function (el) {
          el.classList.remove("hidden")
        })

        if (examplesHidden.length <= 16) {
          this.displayExamplesTarget.classList.add("hidden")
          this.hideExamplesTarget.classList.remove("hidden")
        }
      }
    }
  }

  toggleProducts(e?: Event) {
    e?.preventDefault()

    if (this.hasHideProductsTarget && this.hasDisplayProductsTarget) {
      Array.from(this.hiddenProductTargets).forEach(function (el) {
        el.classList.toggle("!hidden")
      })

      this.hideProductsTarget.classList.toggle("hidden")
      this.displayProductsTarget.classList.toggle("hidden")
    }
  }

  toggleMoreContent() {
    const contentWrapper = document.querySelector(".content-wrapper")

    if (this.contentShowLessTarget.classList.contains("hidden")) {
      contentWrapper?.classList.remove("line-clamp-[9]", "h-[300px]")
      this.contentShowMoreTarget.classList.add("hidden")
      this.contentShowLessTarget.classList.remove("hidden")
    } else {
      contentWrapper?.classList.add("line-clamp-[9]", "h-[300px]")
      this.contentShowMoreTarget.classList.remove("hidden")
      this.contentShowLessTarget.classList.add("hidden")
    }
  }

  loadMore() {
    const signsCount = this.exampleItemTargets.length
    const lastItem = this.exampleItemTargets[signsCount - 1]

    this.loadExamplesTarget.disabled = true

    fetch(
      `/signs/extra_examples?load_more=${signsCount}&id=${this.signtagIdValue}&tag=${this.tagValue}&category=${this.signCategoryIdValue}`,
      {
        method: "GET",
      }
    )
      .then(response => response.json())
      .then(response => {
        const text = response.signs

        this.loadExamplesTarget.disabled = false
        lastItem?.insertAdjacentHTML("afterend", text)
        EpicVite.renderComponents()

        if (response.all_loaded) {
          this.loadExamplesTarget.classList.add("hidden")
          this.hideExamplesTarget.classList.remove("hidden")
        }

        if (this.hasSortablesTarget) {
          draggable(this.sortablesTarget, this.sortablesTarget.dataset.updateUrl)
        }
      })
      .catch(e => {
        console.error("Problem with fetching extra examples:", e)
      })

    return
  }

  adoptSign(e?: Event) {
    const target = e?.target as HTMLElement
    const currentTarget = e?.currentTarget as HTMLElement

    if (
      target.classList.contains("example-to-cart") ||
      target.classList.contains("cart-fill") ||
      target.closest("example-to-cart") ||
      target.classList.contains("example-to-signomatic") ||
      target.closest(".example-to-signomatic") ||
      target.closest(".inline-edit-heading")
    ) {
      return
    }

    e?.preventDefault()

    if (currentTarget.dataset.adoptpath) {
      window.location.href = currentTarget.dataset.adoptpath
    }
  }

  setMobileCategory(e?: Event, categoryIndex?: number) {
    e?.preventDefault()

    const selected = categoryIndex ?? this.examplesMobileCategoryItemTargets.indexOf(e?.currentTarget as HTMLDivElement)
    const selectedCategory = this.examplesMobileCategoryItemTargets[selected]
    const signtags = JSON.parse(selectedCategory.dataset.signtags || "")
    const isActive = parseInt(selectedCategory.dataset.index || "-1") === categoryIndex

    this.signTagsWrapperMobileTarget.innerHTML = ""
    this.selectedCategoryNameTarget.innerHTML = selectedCategory.dataset.name || ""

    if (selectedCategory.dataset.path) {
      this.selectedCategoryLinkTarget.setAttribute("href", selectedCategory.dataset.path)
    }

    for (const signtag of signtags) {
      this.signTagsWrapperMobileTarget.insertAdjacentHTML(
        "beforeend",
        signTagMobileLinkTemplate(
          signtag.pagename,
          signtag.path,
          isActive && parseInt(this.signtagIdValue) === signtag.id
        )
      )
    }

    this.currentlyActive = selected

    this.subMenuTarget.classList.add("visible")

    pushToDataLayer({
      event: "sidebar-category-item-click",
      sidebarCategoryName: selectedCategory.dataset.name,
    })
  }

  openMobileSidebar(e?: Event) {
    e?.preventDefault()

    this.mobileSidebarTarget.classList.add("visible")
    if (this.categoryIndex >= 0) {
      this.setMobileCategory(undefined, this.categoryIndex)
      this.examplesMobileCategoryItemTargets[this.categoryIndex].classList.add("active")
    }
    toggleScroll(true)
  }

  closeMobileSidebar(e?: Event) {
    e?.preventDefault()

    this.mobileSidebarTarget.classList.remove("visible")
    toggleScroll(false)
  }

  closeSubMenu(e?: Event) {
    e?.preventDefault()

    this.subMenuTarget.classList.remove("visible")
  }
}
